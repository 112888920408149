import Router from "vue-router";
import { getAuth } from "firebase/auth"
import init from './utils/runaccs';
const routes = [
  {
    path: "/",
    alias: "/login",
    name: "login",
    component: () => import("./components/pages/Login"),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('./components/pages/Home'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/operations_performance",
    name: "operations_performance",
    component: () => import("./components/pages/OperationsPerformance"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/aircraft_maintenance",
    name: "aircraft_maintenance",
    component: () => import("./components/pages/AircraftMaintenance"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("./components/pages/Orders"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/redirect",
    name: "redirect",
    component: () => import("./components/pages/RedirectOrders"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "*",
    component: { template: "<div>Page not found</div>" }
  }
];

const router = new Router({
  mode: 'history',
  routes: routes
});

router.beforeEach((to, from, next) => {
  // const init = require('../runacc.js');
  init.initialize();

  if (to.matched.some(record => record.meta.authRequired)) {
    //console.log(getAuth())
    if (getAuth().currentUser) {
      next();
    } else {
      alert('You must be logged to see this page');
      next({
        path: '/',
      });
    }
  } else {
    next();
  }
});

export default router
