<template>
  <div id="header">
    <b-navbar sticky toggleable="sm" type="dark" variant="success">

      <b-navbar-brand to="/" >Aerialoop Fleet Management System</b-navbar-brand>
      
      <b-navbar-nav >
        <b-nav-item to="/operations_performance" v-show='user'>Operations Performance</b-nav-item>
        <b-nav-item to="/aircraft_maintenance" v-show='user'>Aircraft Maintenance</b-nav-item>
        <b-nav-item to="/orders" v-show='user'>Orders</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="nav navbar-nav ml-auto" >
        <b-nav-item id="#routerbtn" v-on:click="logout" v-show='user'>Log out</b-nav-item>
      </b-navbar-nav>

    </b-navbar>

    <div class="container mt-3">
      <router-view />
    </div>
  </div>
</template>

<script>
import {getAuth} from "firebase/auth";
import EventBus from "../../event-bus";

export default {
  name: "Header",
  mounted() {
    EventBus.$on("login", () => {
      this.methodInChild();
    });
  },
  methods: {
    logout: function () {
      this.$router.replace('/')
      this.user = false
      getAuth().signOut()
    },
    methodInChild() {
      this.user = true
    },
  },
  data() {
    return {
      user: false,
    }
  },
  created() {
    this.user = !!getAuth().currentUser;
  },

}
</script>
